body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8fafc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

.back-link {
  margin-bottom: 10px;
}

.rmf-select {
  margin-bottom: 10px;
}

.css-fix {
  white-space: pre-wrap; /* or pre-line */
}

.model-header {
  color: #888;
}

.jsonl-preview {
  background-color: #202123;
  color: #189470;
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;
}

.msg-content {
  white-space: pre-line;
}

.sticky-chatbox {
  position: -webkit-sticky;
  position: sticky;
  bottom: 10;
}

.site-detail-header,
.company-checklist-item {
  background: none;
  border: none !important;
  box-shadow: none;
}

.Select-option.is-selected {
  display: none;
}

.prompt-editor {
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid lightgrey;
  font-family: monospace;
}
